import React from 'react';

import { Col, Row } from 'reactstrap';
import { Flex, FlexRow, Navbar } from '../components';
import BaseLayout from '../components/Layouts/BaseLayout';
import useNavigationScroller from '../hooks/useNavigationScroller';
import LargeBar from '../components/Bars/LargeBar';

import PartnersFooter from '../components/PartnersFooter/PartnersFooter';
import { Link } from 'gatsby';
import Markdown from '../components/Markdown/Markdown';
import blogDataJson from '../data/blog.json';
import SideDiamond from '../components/SideDiamond/SideDiamond';

type Props = {
  pageContext: {
    blogEntry: typeof blogDataJson["entries"][0];
  };
}

export const BlogPageInternal = ({ pageContext  }:Props) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  useNavigationScroller(wrapper);

  return (
    <BaseLayout title="Info & Insights">
      <Navbar />

      <Row className="flex-fill">
        <Col md={4}></Col>
        <Col className="d-flex flex-column p-3 flex-fill" md={8}>
          <div className="pt-2 px-2">
            <Link to="/">
              <img className="img-fluid hero-opacity" src={require('../images/logo-horizontal.svg').default} alt="Aviation Safety Solutions" style={{ height: '20vh' }} />
            </Link>
          </div>
        </Col>
      </Row>

      <div className="wrapper" ref={wrapper}>
        <Row>
          <Col className="d-none d-md-block" md={4}>
            <Flex center className="side-affix">
              <SideDiamond label={<span>Info<br/>&<br/>Insights</span>} icon={null} />
            </Flex>
          </Col>
          <Col md={7}>
            <div>
              <Link className="text-secondary" to="/blog">
                <div className="d-flex align-items-center">
                  <i className="mdi mdi-36px mdi-arrow-left" />
                  <span>View All Posts</span>
                </div>
              </Link>
            </div>

            <section className="px-2 body-bg">
              <Row className="mb-5">
                <Col>
                  <h1 className="mb-1 serif" style={{ lineHeight: 1.5 }}>{pageContext.blogEntry.title}</h1>
                  <h4 className="text-secondary">{pageContext.blogEntry.date}</h4>

                  <div>
                    <img className="img-fluid float-none float-md-left pr-3 pb-3" src={pageContext.blogEntry.image} alt={pageContext.blogEntry.title} style={{ width: 300 }} />

                    <div>
                      <Markdown source={pageContext.blogEntry.content} />
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>

        <div className="hero-opacity my-1 mt-5 text-center">
          <LargeBar style={{ width: '60%' }} />
        </div>

        <section className="pt-5">
          <PartnersFooter />
        </section>

        <div className="hero-opacity mt-3 pb-5 text-center text-secondary">
          <FlexRow justifyCenter childSpacingX={1}>
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
          </FlexRow>
        </div>
      </div>
    </BaseLayout>
  );
};

export default BlogPageInternal;
